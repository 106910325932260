import React, {useEffect, useRef, useState, useContext} from 'react'
import { gsap } from 'gsap'
import {navigate} from "gatsby"
import Signin from './components/signin'
import Signup from './components/signupAdmin'
import { AuthContext } from '../firebase'
import {FaChevronDown} from '@react-icons/all-files/fa/FaChevronDown'
import { isMobileOnly } from 'react-device-detect'
import ResetPassword from './components/resetPassword'
import Man from '../images/man'


const Admin = () => {
  const userInit = {myUser: ""}
  const { authUser }  = useContext(AuthContext) || userInit
  const { user } = authUser || userInit

  const [signup, setSignup] = useState(false)
  const [reset, setReset] = useState(false)

  function signSwitch() {
    setSignup(!signup)
  }

  function resetSwitch() {
    setReset(!reset)
  }

  useEffect(() => {
    if(user){
      (user.tenantId === 'ALL') ? navigate('/app/cde')  : navigate("/app/dashboard")
    }
  }, [])


  useEffect(() => {
    gsap.to(".app-home-welcome-head", {opacity: 1, duration: .8, x: 20, ease: "back", delay: .2})
    gsap.to(".app-home-welcome-subhead", {opacity: 1, duration: .8, x: 20, ease: "back"})
    gsap.to(".app-home-welcome-text", {opacity: 1, duration: .8, x: 20, ease: "back", delay: .4})
    gsap.to(".app-home-welcome-text-ref", {opacity: 1, duration: .8, x: 20, ease: "back", delay: .8})
    gsap.to(".app-home-action-switch", {opacity: 1, duration: .8, y: -20, ease: "back", delay: .6})
    isMobileOnly && gsap.to(".arrow-container", {y: -20, yoyo: true, ease: "back", duration: 1.8, repeat: -1})
  },[])


  return (
    // <Layout>
      <div className="app-home">
        <div className="app-home-welcome center">
          <Man myclass="app-man" />
        </div>
        <div className="app-home-action center">
          {signup ? <Signup /> : reset ? <ResetPassword /> : <Signin /> }
          
          <div className="app-home-action-switch">
            {!signup && <p className="">
              <em className="color-4">{reset ? "Vous avez votre mot de passe?" : "Mot de passe oublie?"}&nbsp;</em>
              <span 
                className="color-0"
                onClick={() => resetSwitch()}
                onKeyDown={() => resetSwitch()}
                role="button"
                tabIndex={0}
              >
                {reset ? "Connectez-vous" : "Changer Mot de Passe"}
              </span>
            </p>}
            <p className="">
              <em className="color-4">{signup ? "Vous avez deja un compte?" :"Vous n'avez pas de compte?"} &nbsp;</em>
              <span 
                className="color-0"
                onClick={() => signSwitch()}
                onKeyDown={() => signSwitch()}
                role="button"
                tabIndex={0}
              >
                {signup ? "Connectez-vous" : "Enregistrez-vous"}
              </span>
            </p>
          </div>
        </div>
      </div>
    // </Layout>
  )
}

export default Admin
