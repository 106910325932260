import React, { useContext } from "react"
import { navigate } from "gatsby"
import { AuthContext } from '../firebase'

const PrivateRoute = ({ component: Component, location, ...rest }) => {
  const {authUser} = useContext(AuthContext)
  const {user} = authUser

  if (!user && location.pathname !== `/app`) {
    navigate('/app')
    return null
  }
  return <Component {...rest} />
}
export default PrivateRoute