import React, {useContext, useEffect, useState} from 'react'
import { AuthContext } from '../firebase'
import { FirebaseContext } from '../firebase'
import moment from 'moment'
// import {Row, Col, CardPanel} from 'react-materialize'
import Row from 'react-materialize/lib/Row'
import Col from 'react-materialize/lib/Col'
import { navigate } from 'gatsby'
import * as CONST from './constants'
// import urlSlug from 'url-slug'
import { isMobile } from 'react-device-detect'
import Textarea from '../ui/form/textarea'
import * as Yup from 'yup'
import {Formik, Form, Field} from 'formik'
import { gsap } from 'gsap'

const SMSSchema = Yup.object().shape({
  message: Yup.string().required('Veuillez entrer votre message'),

})

const SMS = () => {
  const firebase = useContext(FirebaseContext)
  const {authUser} = useContext(AuthContext)
  const {user} = authUser
 const [bacc, setBacc] = useState([])

 const [loading, setLoading] = useState(false)

  useEffect(() => {
    user.isAdmin && getPoints()
  },[])

  function getPoints() {

    firebase.db.collection(`user`).orderBy("lastname").onSnapshot(function (snap) {
      let students = []
      snap.forEach(function (doc) {
        const id = doc.id
        students.push({ id, ...doc.data()})
      });
      setBacc(students)
    })
  }

  const sendSMS = (values) => {
    // const{greetings, subject, message} = values

    // const prenom = "Marc"
    // const email = "marcridore@gmail.com"
    const message = values.message
    // console.log('message :>> ', message);

    const to = '+50949007632'

    bacc.map(bac =>{

      const email = bac.email
      const prenom = bac.firstname
      fetch('https://us-central1-elearning-9cb37.cloudfunctions.net/sendSMS', {
        method: 'POST',
        body: JSON.stringify({to, message})
      }).then(res => {
        return res.json();
      })
    })



    setLoading(false)
  }

  let formRef = null

  useEffect(() => {
    gsap.to(formRef.children, {opacity: 1, duration: .8, y: 20, ease: "back", stagger: .1})
  },)

  return (
    <div className="in-app">
      <div className="dashboard">
        <h4 className="color-0 greetings">Salut <span>{user.firstname}</span>,</h4>
        <p className="color-0">Le nombre d'inscris pour le programme du bacc est: <span>{bacc.length}</span></p>
        <Formik
            initialValues={{
              message: '',
            }}
            validationSchema={SMSSchema}
            onSubmit={(values, {setSubmitting}) =>{
              // setLoading(true)
              sendSMS(values)

            }}
          >
            {({isSubmitting, isValid, handleReset}) => (
              <Form className="contact-form" ref={ el => formRef = el}>
                <Field
                  type='text'
                  name='message'
                  placeholder='Votre message ...'
                  component={Textarea}
                />
                <button className="styled-button" type='submit'>Se Connecter</button>
                
              </Form>           
            )}
          </Formik>

      </div>

    </div>

  )
}

export default SMS