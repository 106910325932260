import React, {useState, useEffect, useContext} from 'react';
import {Formik, Form, Field} from 'formik'
import * as Yup from 'yup'
import Input from '../../ui/form/input'
import { gsap } from 'gsap'
import { FirebaseContext } from '../../firebase'
import { AuthContext } from '../../firebase'
import { navigate } from 'gatsby'
import { FaBorderNone } from 'react-icons/fa';

const LoginSchema = Yup.object().shape({
  email: Yup.string().email('Email invalide').required('Veuillez entrer votre email'),
})

const ResetPassword = () => {

  const [sent, setSent] = useState(false)
  const Firebase = useContext(FirebaseContext)
  // const { setAuthUser } = useContext(AuthContext)

  function resetPasswd(email) {
    Firebase.resetPassword(email)
    setSent(!sent)
  }

  useEffect(() => {
    gsap.to(".sign-head", {opacity: 1, duration: .5, y: 20, ease: "back", delay: .4})
    gsap.to(formRef.children, {opacity: 1, duration: .5, y: 20, ease: "back", stagger: .1})
    sent && gsap.from(".sign-success", {opacity: 0, display: "none", ease: "back", duration: .3, y: 20, delay: .2 })
    sent && gsap.to(".sign-reset", {opacity: 0, y: -20, duration: .2, ease: "back"})
  },)

  let formRef = null

  return (
    <div className="sign center">
      <div className="sign-reset">
        <h4 className="sign-head color-0">Changez Mot de Passe</h4>
        <p className="color-1">Pour changer votre mot de passe, veuillez entrer l'addresse email utilis&eacute; pour vous enregistrer</p>
        <Formik
          initialValues={{
            email: ''
          }}
          validationSchema={LoginSchema}
          onSubmit={(values, {setSubmitting}) =>(
            resetPasswd(values.email)
          )}
        >
          {({isSubmitting, isValid, handleReset}) => (
            <Form className="contact-form" ref={ el => formRef = el}>
              <Field
                type='email'
                name='email'
                placeholder='Votre email ...'
                component={Input}
              />
              <button className="styled-button" type='submit'>Envoyer</button>
              
            </Form>           
          )}
        </Formik>
      </div>
      {sent && <div className="sign-success">
        <p className="color-1">Un email a &eacute;t&eacute; envoy&eacute; dans votre boite aux lettres avec un lien pour changer votre mot de passe. Veuillez cliquer sur le lien, changer votre mot de passe et revenir ici pour vous connecter. Merci.</p>
      </div>}
    </div>
  );
};

export default ResetPassword;