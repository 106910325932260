import React, { useEffect, useContext, useState } from 'react';
import {Formik, Form, Field} from 'formik'
import * as Yup from 'yup'
import Input from '../../ui/form/input'
import Select from '../../ui/form/select'
import { gsap } from 'gsap'
import { FirebaseContext } from '../../firebase'
import { AuthContext } from '../../firebase'
import { navigate } from 'gatsby'
import { css } from "@emotion/core"
import BeatLoader from "react-spinners/BeatLoader"
import * as CONST from '../constants'

const LoginSchema = Yup.object().shape({
  code: Yup.string().required('Veuillez entrer votre code'),
  firstname: Yup.string().required('Veuillez entrer votre prenom'),
  lastname: Yup.string().required('Veuillez entrer votre nom'),
  email: Yup.string().email('Email invalide').required('Veuillez entrer votre email'),
  password: Yup.string().required('Veuillez entrer votre mot de passe'),
})

const Signup = () => {
  const override = css`
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  `;

  const [loading, setLoading] = useState(false)
  const [error, setError] = useState(null)

  const firebase = useContext(FirebaseContext)
  const { setAuthUser } = useContext(AuthContext)
  const [code, setCode] = useState(true)

  useEffect(() => {
    gsap.to(".sign-head", {opacity: 1, duration: .8, y: 20, ease: "back", delay: .6})
    !loading && !error && gsap.to(formRef.children, {opacity: 1, duration: .8, y: 20, ease: "back", stagger: .1})
  },)

  let formRef = null

  const createUser = async (values) => {
    const {code, firstname, lastname, isAdmin, email, password} = values

    const response = await firebase.registerAdmin(firstname, lastname, code, isAdmin, email, password)
    if (response.user) {  
      setAuthUser({user: response.user})
      localStorage.setItem('user', JSON.stringify(response.user))
      deleteCode(code)
      navigate("/app/dashboard")
    }else{
      setError(response.error)
      setLoading(false)
    }
  }

  function deleteCode(code) {
    firebase.db.collection("adminList").doc(code).delete().then(function() {
        console.log("Document successfully deleted!");
    }).catch(function(error) {
        console.error("Error removing document: ", error);
    });
  }

  function checkCode(values) {
    console.log('code :>> ', values.code);
    let codeRef = firebase.db.collection("adminList").doc(values.code)

    codeRef.get().then(function (doc) {
      if(doc.exists){
        console.log("found");
        createUser(values)
      }
      else {
        setError({code: 'auth/code-not-found', message: "Votre code est introuvable"})
        setLoading(false)
      }
    })
  }


  return (
    <div className="sign center">
      <h3 className="sign-head color-0">Enregistrez-vous!</h3>
      {
        loading
        ?
          <div className="loader-container">
            <BeatLoader
              css={override}
              size={15}
              color={"#005EB8"}
              loading={loading}
            />
          </div>
        :
          error
          ?
            <div className="error-container">
              <div className="error-message">
                <p>
                  Une erreur est survenue:
                </p>
                <p className="message">
                  {CONST.ERRORS[error.code] ? CONST.ERRORS[error.code] : error.message}
                </p>
                <button onClick={() => setError(null)} className="mybtn color-3 color-2-bg">R&eacute;essayer</button>
              </div>            
            </div>
          :
            <Formik
              initialValues={{
                code: '',
                firstname: '',
                lastname: '',
                email: '',
                password: '',
                isAdmin: true
              }}
              validationSchema={LoginSchema}
              onSubmit={(values, actions) =>{
                setLoading(true)
                checkCode(values)
                actions.resetForm()
              }}
            >
              {({isSubmitting, isValid, handleReset}) => (
                <Form className="contact-form" ref={ el => formRef = el}>
                  <Field
                    type='text'
                    name='code'
                    placeholder='Votre code ...'
                    component={Input}
                  />
                  <Field
                    type='text'
                    name='firstname'
                    placeholder='Votre prenom ...'
                    component={Input}
                  />
                  <Field
                    type='text'
                    name='lastname'
                    placeholder='Votre nom ...'
                    component={Input}
                  />
                  <Field
                    type='email'
                    name='email'
                    placeholder='Votre email ...'
                    component={Input}
                  />
                  <Field
                    type='password'
                    name='password'
                    placeholder='Votre password ...'
                    component={Input}
                  />
                  <button className="styled-button" type='submit'>S'Enregistrer</button>
                  {/* {props.close && handleReset} */}
                </Form>           
              )}
            </Formik>
      }
    </div>
  );
};

export default Signup;