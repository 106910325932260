import React, {useContext, useEffect, useState} from 'react'
import { AuthContext } from '../firebase'
import { FirebaseContext } from '../firebase'
import moment from 'moment'
// import urlSlug from 'url-slug'
import Textarea from '../ui/form/textarea'
import * as Yup from 'yup'
import {Formik, Form, Field} from 'formik'
import { gsap } from 'gsap'
import Input from '../ui/form/input'
import BeatLoader from "react-spinners/BeatLoader"
import { css } from "@emotion/core";
import { Editor } from "@tinymce/tinymce-react";


const SMSSchema = Yup.object().shape({
  greetings: Yup.string().required('Veuillez entrer les salutations'),
  subject: Yup.string().required('Veuillez entrer le sujet de votre message'),
  message: Yup.string().required('Veuillez entrer votre message'),

})

const Email = () => {

  const override = css`
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  `;

  const [loading, setLoading] = useState(false)
  const [content, setContent] = useState("")

  const handleContent = (mycontent, editor) => {
    setContent(mycontent);
  }

  const firebase = useContext(FirebaseContext)
  const {authUser} = useContext(AuthContext)
  const {user} = authUser
  const [bacc, setBacc] = useState([])

  useEffect(() => {
    user.isAdmin && getPoints()
  },[])

  function getPoints() {

    firebase.db.collection(`user`).orderBy("lastname").onSnapshot(function (snap) {
      let mylessons = []
      snap.forEach(function (doc) {
        const id = doc.id
        mylessons.push({ id, ...doc.data()})
      });
      setBacc(mylessons)
    })
  }


  const sendEmail = (values) => {
    const{greetings, subject, message} = values

    // const prenom = "Marc"
    // const email = "marcridore@gmail.com"
    // // const message = content
    // console.log('message :>> ', message);

    bacc.map(bac =>{

      const email = bac.email
      const prenom = bac.firstname
      fetch('https://us-central1-elearning-9cb37.cloudfunctions.net/sendEmail2', {
        method: 'POST',
        body: JSON.stringify({greetings, email, prenom, subject, message})
      }).then(res => {
        return res.json();
      })
    })



    setLoading(false)
  }

console.log('content :>> ', content);
  let formRef = null

  useEffect(() => {
    !loading && gsap.to(formRef.children, {opacity: 1, duration: .8, y: 20, ease: "back", stagger: .1})
  },)



  return (
    <div className="in-app">
      <div className="dashboard">
        <h4 className="color-0 greetings">Salut <span>{user.firstname}</span>,</h4>
        <p className="color-0">Le nombre d'inscris pour le programme du bacc est: <span>{bacc.length}</span></p>
        {
          loading
          ?
            <div className="loader-container">
              <BeatLoader
                css={override}
                size={15}
                color={"#005EB8"}
                loading={loading}
              />
            </div>
          :
            <Formik
                initialValues={{
                  greetings: '',
                  subject: '',
                }}
                validationSchema={SMSSchema}
                onSubmit={(values, {setSubmitting}) =>{
                  setLoading(true)
                  sendEmail(values)
                }}
              >
                {({isSubmitting, isValid, handleReset}) => (
                  <Form className="contact-form" ref={ el => formRef = el}>
                    <Field
                      type='text'
                      name='greetings'
                      placeholder='Les salutations ...'
                      component={Input}
                    />
                    <Field
                      type='text'
                      name='subject'
                      placeholder='Le sujet de votre message ...'
                      component={Input}
                    />
                    {/* <Editor    
                      apiKey="a5sgp5zh1sxo05oho8rz5jpbzr3hnnhk21ug6sgp0foojuqr"
                      value={content}
                      init={{
                        height: 500,
                        menubar: false
                      }}
                      onEditorChange={handleContent}
                    /> */}
                    <Field
                      type='text'
                      name='message'
                      placeholder='Votre message ...'
                      component={Textarea}
                    />
                    <button className="styled-button" type='submit'>Envoyer</button>
                    
                  </Form>           
                )}
              </Formik>
        }

      </div>

    </div>

  )
}

export default Email