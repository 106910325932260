import React, { useEffect, useRef, useState, useContext} from 'react'
import '../styles/sidenav.css'
import { gsap } from 'gsap'
import {FaBars} from '@react-icons/all-files/fa/FaBars'
import { isMobile } from 'react-device-detect'
import { navigate} from "gatsby";
import { AuthContext } from '../firebase'
import { FirebaseContext } from '../firebase'
import {StaticImage} from 'gatsby-plugin-image'

const Sidenav = () => {

  const { authUser, setAuthUser }  = useContext(AuthContext)
  const {user} = authUser

  const [active, setActive] = useState(false)

  let headText = useRef(null)
  let items = useRef(null)

  useEffect(() => {
    gsap.from(headText, {opacity: 0, duration: 1.8, y: -20, ease: "back", delay: .6})
    gsap.from(items.children, {opacity: 0, duration: .8, y: -20, ease: "back", delay: .5, stagger: .1})
    // gsap.from(".mysidenav", {opacity: 0, duration: .8, x: -20, ease: "power.Out", delay: .2})

    isMobile && active && gsap.to(".navcontrol", {x:220, duration: 0.5})
    isMobile && !active && gsap.to(".navcontrol", {x:0, duration: 0.5, delay:.2})
    active && gsap.to(".mysidenav", {x:220, duration: 0.5, delay:.2})
    !active && gsap.to(".mysidenav", {x:0, duration: 0.5})

  }, [headText, items, active])


  function changeNav(){
    isMobile && setActive(!active)
  }

  const fb = useContext(FirebaseContext)

  const logoutUser = async () =>{
    await fb.logout()
    setAuthUser({user: ""})
    localStorage.removeItem('user')
  }

  
  function handleClick(path) {
    navigate(`${path}`)
    isMobile && setActive(!active)
  }

  return(
    <div>
      {isMobile && <button 
        className= "circle white navcontrol"
        onClick={changeNav}
      >
        <FaBars className="navopen color-1-text" />
      </button>}
      <div>
        <div className="appsidenav">
          <div className="sidenav-head center" role ="button" tabIndex={0} onClick={() => handleClick('/app/dashboard')} onKeyDown={() => handleClick('/app/dashboard')}>
          {/* <Link to="/"> */}
            <StaticImage src="../images/logowhite.svg" height="50" layout="fixed" className="svglogoside" placeholder="none" alt="Bizhightech logo sidenav" />
              <h3 ref={el => headText = el} className="sidenav-head-logo-text color-2">BizHighTech</h3>                   
          {/* </Link> */}
            </div>
          <ul ref={ el => items = el} className="sidenav-item-list">
            <div onClick={() => handleClick('/app/sms')} role ="button" tabIndex={0} onKeyDown={() => handleClick('/app/sms')} className="sidenav-btn color-2">
              Send SMS
            </div>
            <div onClick={() => handleClick('/app/email')} role ="button" tabIndex={0} onKeyDown={() => handleClick('/app/email')} className="sidenav-btn color-2">
              Send Email
            </div>
            <div className="">
              <div className="sidenav-btn color-2" onClick={() => logoutUser()} role ="button" tabIndex={0} onKeyDown={() => logoutUser()} >D&eacute;connexion</div>
            </div>
          </ul>
        </div>
      </div>
    </div>
  )
  
}

export default Sidenav
