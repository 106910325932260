export const CATEGORYMAP = ["19 à 22 ans", "15 à 18 ans", "12 à 14 ans", "9 à 11 ans"]
export const CDELIST = ['HA0727',	'HA0152',	'HA0202',	'HA0313',	'HA0314',	'HA0353',	'HA0726',	'HA0748',	'HA0802',	'HA0805',	'HA0829',	'HA0833',	'HA0835',	'HA0916',	'HA0937']
export const ERRORS = {
  'auth/wrong-password': 'Votre mot de passe est incorrect',
  'auth/user-not-found': "Nous n'avons pas trouvé d'utilisateur correspondant à cet email",
  'auth/invalid-email': "L'adresse email n'est pas valide",
  'auth/user-disabled': "L'utilisateur a été désactivé",
  'auth/email-already-in-use': "L'adresse email est déjà en utilisation",
  'auth/weak-password': "Le mot de passe est trop faible",
  'auth/network-request-failed': "Une erreur de réseau s'est produite, veuillez verifier votre connection",
  'auth/code-not-found': "Votre code est introuvable"
}
