import React from 'react'
import { Router } from '@reach/router'
import PrivateRoute from '../app/privateRoute'
import Layout from "../components/appLayout"
import Default from '../app/default'
import Admin from '../app/admin'
import Dashboard from '../app/dashboard'
import SMS from '../app/sendSMS'
import Email from '../app/sendEmail'

const App = () => {
  return (
    <Layout>
      <Router basepath="/app">
        <PrivateRoute path="/dashboard" component={Dashboard}/>
        <PrivateRoute path="/sms" component={SMS}/>
        <PrivateRoute path="/email" component={Email}/>
        {/* <PrivateRoute path="/results" component={Results}/>
        <PrivateRoute path="/resultsinter" component={ResultsInter}/>
        <PrivateRoute path="/results/:id" component={Result}/>
        <PrivateRoute path="/lessons" component={Lessons}/>
        <PrivateRoute path="/lessons/:slug" component={Lesson}/>
        <PrivateRoute path="/createlesson" component={CreateLesson}/>
        <PrivateRoute path="/createtest" component={CreateTest}/>
        <PrivateRoute path="/createtestinter" component={CreateTestInter}/>
        <PrivateRoute path="/tests" component={Tests}/>
        <PrivateRoute path="/stats" component={Stats}/>
        <PrivateRoute path="/testsinter" component={TestsInter}/>
        <PrivateRoute path="/tests/:id" component={Quiz}/> */}
        <Default path="/"/>
        <Admin path="/admin" />
        {/* <Monitor path="/moniteur" /> */}
        {/* <Staff path="/staff" /> */}
      </Router>
    </Layout>
  )
}

export default App