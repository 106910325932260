import React, {useContext, useEffect, useState} from 'react'
import { AuthContext } from '../firebase'
import { FirebaseContext } from '../firebase'
import moment from 'moment'
// import {Row, Col, CardPanel} from 'react-materialize'
import Row from 'react-materialize/lib/Row'
import Col from 'react-materialize/lib/Col'
import { navigate } from 'gatsby'
import * as CONST from './constants'
// import urlSlug from 'url-slug'
import { isMobile } from 'react-device-detect'

const Dashboard = () => {
  const firebase = useContext(FirebaseContext)
  const {authUser} = useContext(AuthContext)
  const {user} = authUser
 const [bacc, setBacc] = useState([])

  useEffect(() => {
    user.isAdmin && getPoints()
  },[])

  function getPoints() {

    firebase.db.collection(`user`).orderBy("lastname").onSnapshot(function (snap) {
      let mylessons = []
      snap.forEach(function (doc) {
        const id = doc.id
        mylessons.push({ id, ...doc.data()})
      });
      setBacc(mylessons)
    })
  }

  console.log('bacc :>> ', bacc.length);

  return (
    <div className="in-app">
      <div className="dashboard">
        <h4 className="color-0 greetings">Salut <span>{user.firstname}</span>,</h4>
        <p className="color-0">Le nombre d'inscris pour le programme du bacc est: <span>{bacc.length}</span></p>
        <Row>
          {
            bacc.map(bac => {
              return(
                <Col l={4} m={6} s={12} key={bac.id}>
                  <div className="bacc mycard color-1 color-2-bg">
                    <h4 className="color-1">{`${bac.firstname} ${bac.lastname}`}</h4>
                    <p><strong>Ville:</strong> {bac.city}</p>
                    <p><strong>Email:</strong> {bac.email}</p>
                    <p><strong>Phone:</strong> {bac.phone}</p>
                    <p><strong>Interet:</strong> {bac.interest.map( inter => <span>{inter} </span>)}</p>

                  </div>
                  
                </Col>
              )
            })
          }
        </Row>

      </div>

    </div>

  )
}

export default Dashboard