
import React, {useContext} from "react"
import PropTypes from "prop-types"
import "./layout.css"
import Nav from "./appNav"
import Sidenav from "../ui/appsidenav"
import { AuthContext } from '../firebase'


const AppLayout = ({ children }) => {

  const userInit = {myUser: ""}
  const { authUser }  = useContext(AuthContext) || userInit
  const { user } = authUser || userInit


  return (
    <main className="app-main">
      {!user && <Nav />}
      {user && <Sidenav />}
      <div className="children">
        {children}
      </div>
    </main>    
  )
}

AppLayout.propTypes = {
  children: PropTypes.node.isRequired,
}

export default AppLayout
