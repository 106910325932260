import React, { useEffect, useContext, useState } from 'react';
import {Formik, Form, Field} from 'formik'
import * as Yup from 'yup'
import Input from '../../ui/form/input'
import Select from '../../ui/form/select'
import { gsap } from 'gsap'
import { FirebaseContext } from '../../firebase'
import { AuthContext } from '../../firebase'
import { navigate } from 'gatsby'
import { css } from "@emotion/core"
import BeatLoader from "react-spinners/BeatLoader"
import * as CONST from '../constants'

const LoginSchema = Yup.object().shape({
  code: Yup.string().required('Veuillez entrer votre code'),
  email: Yup.string().email('Email invalide').required('Veuillez entrer votre email'),
  password: Yup.string().required('Veuillez entrer votre mot de passe'),
})

const Signup = () => {
  const override = css`
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  `;

  const [loading, setLoading] = useState(false)
  const [error, setError] = useState(null)

  const firebase = useContext(FirebaseContext)
  const { setAuthUser } = useContext(AuthContext)
  const [code, setCode] = useState(true)

  useEffect(() => {
    gsap.to(".sign-head", {opacity: 1, duration: .8, y: 20, ease: "back", delay: .6})
    !loading && !error && gsap.to(formRef.children, {opacity: 1, duration: .8, y: 20, ease: "back", stagger: .1})
  },)

  let formRef = null

  const createUser = async (values, fullName, tenantId) => {
    const {code, category, isMonitor, email, password} = values

    const response = await firebase.register(fullName, code, tenantId, category, isMonitor, email, password)
    if (response.user) {      
      setAuthUser({user: response.user})
      localStorage.setItem('user', JSON.stringify(response.user))
      deleteCode(code)
      navigate("/app/dashboard")
    }else{
      setError(response.error)
      setLoading(false)
    }
  }

  function deleteCode(code) {
    firebase.db.collection("childrenList").doc(code).delete().then(function() {
        console.log("Document successfully deleted!");
    }).catch(function(error) {
        console.error("Error removing document: ", error);
    });
  }

  function checkCode(values) {
    // firebase.db.collection("userlist").where("code", "==", code).onSnapshot(handleSnapshot)
    console.log('code :>> ', values.code);
    let codeRef = firebase.db.collection("childrenList").doc(values.code)

    codeRef.get().then(function (doc) {
      if(doc.exists){
        console.log("found");
        createUser(values, doc.data().fullName, doc.data().tenantId)
        // deleteCode(values.code)
      }
      else {
        setError({code: 'auth/code-not-found', message: "Votre code est introuvable"})
        setLoading(false)
      }
    })
  }


  return (
    <div className="sign center">
      <h3 className="sign-head color-0">Enregistrez-vous!</h3>
      {
        loading
        ?
          <div className="loader-container">
            <BeatLoader
              css={override}
              size={15}
              color={"#005EB8"}
              loading={loading}
            />
          </div>
        :
          error
          ?
            <div className="error-container">
              <div className="error-message">
                <p>
                  Une erreur est survenue:
                </p>
                <p className="message">
                  {CONST.ERRORS[error.code] ? CONST.ERRORS[error.code] : error.message}
                </p>
                <button onClick={() => setError(null)} className="mybtn color-3 color-2-bg">R&eacute;essayer</button>
              </div>            
            </div>
          :
            <>
              
              <Formik
                initialValues={{
                  code: '',
                  // category: '',
                  email: '',
                  password: '',
                  isMonitor: false
                }}
                validationSchema={LoginSchema}
                onSubmit={(values, actions) =>{
                  setLoading(true)
                  checkCode(values)
                  actions.resetForm()
                }}
              >
                {({isSubmitting, isValid, handleReset}) => (
                  <Form className="contact-form" ref={ el => formRef = el}>
                    <Field
                      type='text'
                      name='code'
                      placeholder='Votre code ...'
                      component={Input}
                    />
                    <Field
                      type='email'
                      name='email'
                      placeholder='Votre email ...'
                      component={Input}
                    />
                    {/* <Field 
                      as="select" 
                      name="category"
                      component={Select}
                      placeholder='Categorie'
                    >
                      <option value="" disabled>Categorie</option>
                      <option value={3}>9 &agrave; 11 ans</option>
                      <option value={2}>12 &agrave; 14 ans</option>
                      <option value={1}>15 &agrave; 18 ans</option>
                      <option value={0}>19 &agrave; 22 ans</option>
                    </Field> */}

                    <Field
                      type='password'
                      name='password'
                      placeholder='Votre password ...'
                      component={Input}
                    />
                    <button className="styled-button" type='submit'>S'Enregistrer</button>
                    {/* {props.close && handleReset} */}
                  </Form>           
                )}
              </Formik>
            </>
      }
    </div>
  );
};

export default Signup;