import React, {useEffect, useContext, useState} from 'react';
import {Formik, Form, Field} from 'formik'
import * as Yup from 'yup'
import Input from '../../ui/form/input'
import { gsap } from 'gsap'
import { FirebaseContext } from '../../firebase'
import { AuthContext } from '../../firebase'
import { navigate } from 'gatsby'
import { css } from "@emotion/core";
import PulseLoader from "react-spinners/PulseLoader"
import BeatLoader from "react-spinners/BeatLoader"
import * as CONST from '../constants'

const LoginSchema = Yup.object().shape({
  email: Yup.string().email('Email invalide').required('Veuillez entrer votre email'),
  password: Yup.string().required('Veuillez entrer votre mot de passe'),
})

const Signin = () => {

  const override = css`
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  `;

  const [loading, setLoading] = useState(false)
  const [error, setError] = useState(null)

  const Firebase = useContext(FirebaseContext)
  const { setAuthUser } = useContext(AuthContext)

  const authenticateUser = async (values) => {
    const { email, password} = values
    const response = await Firebase.login(email, password)
    console.log('response :>> ', response);
    if (response) {  
    setAuthUser({user: response})
    console.log('response :>> ', response);
    localStorage.setItem('user', JSON.stringify(response))
    const path = "/app/dashboard"
    navigate(`${path}`)
    }else{
      setError(response)
      setLoading(false)
    }
  }

  useEffect(() => {
    gsap.to(".sign-head", {opacity: 1, duration: .8, y: 20, ease: "back", delay: .4})
    !loading && !error && gsap.to(formRef.children, {opacity: 1, duration: .8, y: 20, ease: "back", stagger: .1})
  },)

  let formRef = null
  console.log('error :>> ', error);

  return (
    <div className="sign center">
      <h3 className="sign-head color-0">Connectez-vous!</h3>
      {
        loading
        ?
          <div className="loader-container">
            <BeatLoader
              css={override}
              size={15}
              color={"#005EB8"}
              loading={loading}
            />
          </div>
        :
        error
        ?
          <div className="error-container">
            <div className="error-message">
              <p>
                Une erreur est survenue:
              </p>
              <p className="message">
                {CONST.ERRORS[error.code] ? CONST.ERRORS[error.code] : error.message}
              </p>
              <button onClick={() => setError(null)} className="mybtn color-3 color-2-bg">R&eacute;essayer</button>
            </div>            
          </div>
        :
          <Formik
            initialValues={{
              email: '',
              password: ''
            }}
            validationSchema={LoginSchema}
            onSubmit={(values, {setSubmitting}) =>{
              setLoading(true)
              authenticateUser(values)
            }}
          >
            {({isSubmitting, isValid, handleReset}) => (
              <Form className="contact-form" ref={ el => formRef = el}>
                <Field
                  type='email'
                  name='email'
                  placeholder='Votre email ...'
                  component={Input}
                />
                <Field
                  type='password'
                  name='password'
                  placeholder='Votre password ...'
                  component={Input}
                />
                <button className="styled-button" type='submit'>Se Connecter</button>
                
              </Form>           
            )}
          </Formik>
      }
      
    </div>
  );
};

export default Signin;